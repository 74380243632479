let video = document.querySelector("#video"),
    cTrigger = document.querySelector('#captureTrigger');

// VIDEO SETUP
// Prefer camera resolution nearest to 1280x720.
const constraints = {
    audio: false,
    video: { width: 1280, height: 880 }
};
  
navigator.mediaDevices.getUserMedia(constraints)
    .then((mediaStream) => {
        const video = document.querySelector('video');
        video.srcObject = mediaStream;
        video.setAttribute('autoplay', '');
        video.setAttribute('muted', '');
        video.setAttribute('playsinline', '');
        video.onloadedmetadata = () => {
            video.play();
        };
    })
    .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`);
});




let i = 1;
let id = setInterval(rafale, 50);

let innerWidth = document.body.clientWidth;
let innerHeight = window.innerHeight || document.body.clientHeight;
let amount = 100;
let area = Math.round(innerWidth / amount)

function rafale() {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.height = innerHeight; canvas.width = area;
    canvas.setAttribute('id', i)

    let old = document.querySelectorAll('canvas')

    if(old.length) {
        old.forEach(el => {
            if(parseInt(el.getAttribute('id')) === i) {
                el.replaceWith(canvas)
                ctx.drawImage(video, 640, 0, area, canvas.height, 0, 0, area, canvas.height);
            }
        });
        document.body.appendChild(canvas)
        ctx.drawImage(video, 640, 0, area, canvas.height, 0, 0, area, canvas.height);

    } else {
        document.body.appendChild(canvas)
        ctx.drawImage(video, 640, 0, area, canvas.height, 0, 0, area, canvas.height);
    }

    i++;

    if(i === 100) {
        i = 1
    }
}